import type { OfferFull, OfferMedium, OfferSmall } from '~/types/NewTypes/CatalogNew';
import type { Offer } from '~/types/Product';

export function useGtmModule() {
  const gtm = useGtm();

  async function pushEcommerceToDataLayer(
    eventName: string,
    items: Record<string, any>[],
    additionalFields?: Record<string, any>,
  ) {
    gtm?.push({ ecommerce: null });

    const data = additionalFields
      ? { event: eventName, ecommerce: { items, ...additionalFields } }
      : { event: eventName, ecommerce: { items } };

    gtm?.push(data);
  }

  function prepareProductsDataForDataLayer(offers: OfferMedium[]) {
    const items = [];

    for (const offer of offers) {
      const index = offers.findIndex((o) => o.id === offer.id);

      items.push({
        item_id: offer.id,
        item_name: offer.product_name,
        item_variant: offer.displayable_offer_properties.length ? offer.displayable_offer_properties[0].value : null,
        price: offer.price,
        index: index > -1 ? index + 1 : null,
      });
    }

    return items;
  }

  function prepareOffersDataForDataLayer(offers: OfferFull[] | Offer[]) {
    const items = [];

    for (const offer of offers) {
      items.push({
        item_id: offer.id,
        item_name: offer.product_name || offer.product?.name || null,
        item_brand: offer.brand?.name || null,
        item_variant: offer.displayable_offer_properties?.length ? offer.displayable_offer_properties[0].value : null,
        price: offer.price || null,
        quantity: offer.count || null,
      });
    }

    return items;
  }

  async function sendProductsListViewEventToDataLayer(offers: OfferMedium[]) {
    await pushEcommerceToDataLayer('view_item_list', prepareProductsDataForDataLayer(offers));
  }

  async function sendProductEventToDataLayer(
    eventName: string | 'select_item' | 'view_item',
    offer: OfferFull | OfferMedium,
    index?: number,
  ) {
    await pushEcommerceToDataLayer(eventName, [
      {
        item_id: offer.id,
        item_name: offer.product_name,
        item_variant: offer.displayable_offer_properties?.length ? offer.displayable_offer_properties[0].value : null,
        // @ts-ignore
        price: offer.price || null,
        index: index ? index + 1 : null,
      },
    ]);
  }

  async function sendOfferEventToDataLayer(
    eventName: string | 'add_to_cart' | 'remove_from_cart' | 'add_to_wishlist' | 'remove_from_wishlist',
    offer: OfferFull | OfferMedium | OfferSmall,
  ) {
    await pushEcommerceToDataLayer(eventName, [
      {
        item_id: offer.id,
        item_name: offer.product_name,
        // @ts-ignore
        item_brand: offer.brand?.name ?? null,
        item_variant: offer.displayable_offer_properties?.length ? offer.displayable_offer_properties[0].value : null,
        // @ts-ignore
        price: offer.price || null,
        quantity: 1,
      },
    ]);
  }

  async function sendCartViewEventToDataLayer(offers: OfferFull[]) {
    await pushEcommerceToDataLayer('view_cart', prepareOffersDataForDataLayer(offers));
  }

  async function sendCheckoutViewEventToDataLayer(offers: OfferFull[]) {
    await pushEcommerceToDataLayer('begin_checkout', prepareOffersDataForDataLayer(offers));
  }

  async function sendCheckoutPaymentSelectEventToDataLayer(offers: OfferFull[], paymentType: string) {
    await pushEcommerceToDataLayer('add_payment_info', prepareOffersDataForDataLayer(offers), {
      payment_type: paymentType,
    });
  }

  async function sendCheckoutShippingSelectEventToDataLayer(offers: Offer[], shippingTier: string) {
    await pushEcommerceToDataLayer('add_shipping_info', prepareOffersDataForDataLayer(offers), {
      shipping_tier: shippingTier,
    });
  }

  async function sendPurchaseEventToDataLayer(offers: OfferFull[], additionalFields: Record<string, any>) {
    await pushEcommerceToDataLayer('purchase', prepareOffersDataForDataLayer(offers), additionalFields);
  }

  return {
    sendProductsListViewEventToDataLayer,
    sendProductEventToDataLayer,
    sendOfferEventToDataLayer,
    sendCartViewEventToDataLayer,
    sendCheckoutViewEventToDataLayer,
    sendCheckoutPaymentSelectEventToDataLayer,
    sendCheckoutShippingSelectEventToDataLayer,
    sendPurchaseEventToDataLayer,
  };
}
